@import "./globals/custom.css";
@import "./globals/burger-menu.css";
@import "./globals/menu.css";
@import "./globals/custom-global-styles.css";

/* defaults for the page (like the text color, background color, or font family) */
@import "tailwindcss/base";
@import "./base/_index.css";

/* components layer for any more complicated classes (like card, btn, badge) */
@import "tailwindcss/components";
@import "./components/custom-components.css";

/* CSS features that Tailwind doesn’t include utilities for out of the box */
@import "tailwindcss/utilities";
@import "./utilities/custom-utilities.css";
