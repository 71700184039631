@font-face {
  font-family: ReferenzGrotesk;
  font-weight: 400;
  font-style: normal;
  src: url(/fonts/ReferenzGroteskYoself-Regular.woff2) format('woff2'),
    url(/fonts/ReferenzGroteskYoself-Regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: ReferenzGrotesk;
  font-weight: 700;
  font-style: normal;
  src: url(/fonts/ReferenzGroteskYoself-Bold.woff2) format('woff2'),
    url(/fonts/ReferenzGroteskYoself-Bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: ReferenzGrotesk;
  font-weight: 800;
  font-style: normal;
  src: url(/fonts/ReferenzGroteskYoself-ExtraBold.woff2) format('woff2'),
    url(/fonts/ReferenzGroteskYoself-ExtraBold.woff) format('woff');
  font-display: swap;
}

html {
  -webkit-overflow-scrolling: touch;
  /* scroll-behavior: smooth; */
}

.slick-prev {
  left: 20px !important;
  z-index: 30;
}
.slick-next {
  right: 20px !important;
  z-index: 30;
}

.slick-next::before,
.slick-prev::before {
  color: #000;
}

.yt-lite:hover > .lty-playbtn,
.lty-playbtn {
  @apply bg-white rounded-full p-12 shadow-2xl;
  background-color: #fff !important;
  border-radius: 100% !important;
  opacity: 1 !important;
}

.yt-lite.lyt-activated:before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0 !important;
}

.yt-lite:hover > .lty-playbtn {
  background-color: #000 !important;
}

.yt-lite > .lty-playbtn:before {
  border-width: 18px 0 18px 29px !important;
  margin-left: 5px;
  border-color: transparent transparent transparent #000 !important;
}

.yt-lite:hover > .lty-playbtn:before {
  border-color: transparent transparent transparent #fff !important;
}

.yt-lite:before {
  display: none !important;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex !important;
  @apply justify-center items-center;
}

.box {
  @apply shadow-2xl;
  position: relative;
  width: 84vw;
  max-width: 946px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  padding-top: 40px;
  overflow: auto;
}

.box-portrait {
  width: 100%;
  height: 86vh;
  max-width: 480px;
  max-height: 880px;
}

.box-small {
  @apply shadow-2xl;
  position: relative;
  width: 96%;
  max-width: 580px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  border-radius: 4px;
  padding-top: 20px;
  overflow: auto;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
  left: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  z-index: 999999;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container-portrait {
  position: relative;
  padding-bottom: 26.25%;
  padding-top: 0;
  height: 100%;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container-portrait iframe,
.video-container-portrait object,
.video-container-portrait embed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video-container-wrapper2 {
  max-width: 100%;
  width: 600px;
  left: 0;
  right: 0;
  margin: auto;
}

/* @media screen and (max-height: 640px) and (orientation: landscape) {
  .stage {
    background-position: center -30px !important;
  }
  .stage-text {
    margin-top: -10vh;
  }
} */

.stage {
  background-size: cover !important;
}

@media screen and (max-height: 540px) and (max-width: 768px) {
  .stage {
    background-position: center -40px !important;
  }
  .stage-text {
    margin-top: -10vh;
  }
}

@media screen and (max-height: 640px) and (max-width: 1023px) {
  .stage {
    background-position: center 0px;
  }
  .stage-text {
    margin-top: -10vh !important;
  }

  .stage.subpage {
    background-position: center -20px;
  }

  .stage-text-subpage {
    padding-bottom: 10px !important;
  }
}

.slide-testimonial {
  display: flex !important;
}

.testimonials .slick-dots {
  position: unset;
  margin-top: -20px;
}

.testimonials .slick-slider {
  max-width: 1100px;
  margin: auto;
}

.slick-slide > div {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .square {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
  }

  .square-wrapper {
    padding-left: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1140px) {
  .square {
    width: 100%;
    padding-bottom: 100%;
  }
  .square-wrapper {
    padding-left: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1360px) {
  .square {
    width: 100%;
    padding-bottom: 100%;
  }
  .square-wrapper {
    padding-left: 20%;
  }
}

.triangle {
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 10px 0 10px 17.3px;
  border-color: transparent transparent transparent #000;
}

button:hover .triangle {
  border-color: transparent transparent transparent #fff;
}

.black-gradient2 {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.95) 40%,
    rgba(0, 0, 0, 0.75) 80%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.black-gradient {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.95) 20%,
    rgba(0, 0, 0, 0.75) 90%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

@media screen and (min-width: 768px) {
  .black-gradient {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.9) 40%,
      rgba(0, 0, 0, 0.9) 50%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
}

@media screen and (max-width: 768px) {
  .slider-experts {
    width: 87%;
    overflow: visible;
  }
  .slider-experts .slick-list {
    overflow: visible;
  }
  .slider-experts .slick-dots {
    right: -1.8rem !important;
  }
}

.slider-experts .slick-dots {
  bottom: -50px;
}

.slider-experts .slick-list {
  margin-right: -1.5rem !important;
}

@media screen and (min-width: 2160px) {
  .slider-experts .slick-list {
    margin-right: -2.5rem !important;
  }
}

.slider-experts .slick-next {
  right: 10px !important;
}

.slider-experts .slick-prev {
  left: 10px !important;
}

.slider-experts .slick-prev,
.slider-experts .slick-prev:focus,
.slider-experts .slick-prev:active,
.slider-experts .slick-next,
.slider-experts .slick-next:focus,
.slider-experts .slick-next:active {
  top: 40% !important;
  padding: 12px !important;
  border-radius: 100px;
  background-color: white;
  height: auto;
  width: auto;
  opacity: 0.7;
}

.slider-experts .slick-prev:hover,
.slider-experts .slick-next:hover {
  opacity: 1;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .slider-experts .slick-prev,
  .slider-experts .slick-next {
    display: none !important;
  }
}

.scroll-offset:before {
  content: '';
  display: block;
  height: 72px;
  margin: -72px 0 0;
}
