.slick-prev {
  left: 15px !important;
  z-index: 30;
}
.slick-next {
  right: 15px !important;
  z-index: 30;
}

.slick-next::before,
.slick-prev::before {
  color: #000 !important;
}

.slick-dots li button::before {
  color: #000 !important;
  font-size: 40px !important;
  font-family: 'Poppins', Arial !important;
}

.slick-dots li hover:button {
  border: 2px solid #000 !important;
}

.reflect-hydeep-red:before {
  position: absolute;
  top: 67%;
  left: 5.5%;
  width: 89.5%;
  height: 20%;
  content: '';
  background: linear-gradient(rgba(229, 59, 33, 0.3), transparent);
}

.reflect-hydeep-rose:before {
  position: absolute;
  top: 67%;
  left: 5.5%;
  width: 89.5%;
  height: 20%;
  content: '';
  background: linear-gradient(rgba(224, 152, 160, 0.3), transparent);
}

.reflect-hydeep-turquoise:before {
  position: absolute;
  top: 63%;
  left: 5.5%;
  width: 89.5%;
  height: 25%;
  content: '';
  background: linear-gradient(rgba(0, 204, 190, 0.3), transparent);
}


@media screen and (max-width: 1024px) {
  .reflect-hydeep-red:before {
    top: 66%;
  }

  .reflect-hydeep-rose:before {
    top: 66%;
  }

  .reflect-hydeep-turquoise:before {
    top: 63%;
  }
}

@media screen and (max-width: 768px) {
  .reflect-hydeep-red:before {
    top: 60%;
  }

  .reflect-hydeep-rose:before {
    top: 60%;
  }

  .reflect-hydeep-turquoise:before {
    top: 56%;
  }
}

@media screen and (max-width: 640px) {
  .sticky-bar p {
    display: none;
  }
  .sticky-bar > button > div {
    margin: 0;
  }
  .sticky-bar > button {
    padding: 0.75rem 1.75rem;
  }

  .reflect-hydeep-red:before {
    top: 75%;
  }

  .reflect-hydeep-rose:before {
    top: 75%;
  }

  .reflect-hydeep-turquoise:before {
    top: 71%;
  }

}

@media screen and (max-width: 375px) {
  .reflect-hydeep-red:before {
    top: 69%;
  }

  .reflect-hydeep-rose:before {
    top: 69%;
  }

  .reflect-hydeep-turquoise:before {
    top: 69%;
  }
}

@media screen and (max-width: 320px) {
  .reflect-hydeep-red:before {
    top: 65%;
  }

  .reflect-hydeep-rose:before {
    top: 65%;
  }

  .reflect-hydeep-turquoise:before {
    top: 62%;
  }
}
